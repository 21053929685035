import React, { Component,useState } from "react";
import axios from "../../../config/axios";
import { Row, Col, Modal, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Header, HeaderTop } from "../../../components/headers";
import { FooterLink } from "../../../components/footers";
// import { Google, Facebook } from "../social";
import validation from '../../../Validate/validator';
import message from '../../../utils/mesaages';
import PhoneInput from 'react-phone-input-2'
import {showPhonecountry,CaptchaKey} from "../../../config/custom";
import ReCAPTCHA from "react-google-recaptcha";
import OTPInput from "otp-input-react";
import SocilaLogin from "../../../layouts/SocilaLogin";
import { generatePassword } from "../../../config/custom";
import { ALLLOW_FREE_SUBSCRIPTION } from "../../../config/custom";

class JobSeekerRegistration extends Component {
    constructor(props, context) {
        super(props, context);
        this.token = localStorage.getItem("authToken")
        this.acode = false;
        this.userdata = localStorage.getItem("userdata")
        this.state = {
            otp_validated : true,
            show_otp_modal : false,
            otp_msg : "",
            msg_password : "",
            otp_msg_class : "",
            recapcha  : "",
            package: '1',
            coupon : "",
            acode_msg : "",
            acode_class : "",
            registration_source : "",
            acode : "",
            fastname: "",
            lastname: "",
            email: "",
            phone_number: "",
            password: "",
            RepeatPassword: "",
            country: [],
            user_type: 'jobseeker',
            trialtext: 0,
            getNoti: false,
            FromDataError: {
                recapcha : "false",
                fastname: "",
                lastname: "",
                email: 'false',
                phone_number: '',
                password: '',
                RepeatPassword: '',
            },
            socialState: true,
            countryName: 'us',
            acceptTerms: true,
            termsModal: false,
            termsdata: [],
            content: '',
            cont: '',
            link_text: ''
        };
        this.handleResendOTP = this.handleResendOTP.bind(this);
        this.handleSubmitOTP = this.handleSubmitOTP.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);  
       
    }

    

    checkCoupon = (coupon) => {
        
        this.setState({ loader: true });
        const hideMessage = message.loading('Please wait, we are Validating the Coupon code', 0)
        const formData = {
            coupon: coupon,
        };
    
        axios.post("/check-coupon-code", formData).then((response) => {
            hideMessage()
            if (response.data.success) {
                this.setState({ loader: false })
                if(response.data.isValid == 'YES'){
                    this.setState({ coupon: coupon,acode_msg:response.data.message,acode_class : "success",package : response.data.plan_id });
                }else{
                    this.setState({ coupon: "",acode_msg:response.data.message,acode_class : "danger" });
                }
            }
        })
        .catch((error) => {
            hideMessage()
            message.error(error.response.data.message)
            this.setState({ loader: false })
            this.setState({ acode: "",registration_source:"" });
        });   
    }
    checkAffiliateCode = (acode,source) => {
        
        this.setState({ loader: true });
        const hideMessage = message.loading('Please wait, we are Validating the Affiliate code', 0)
        const formData = {
            acode: acode,
        };
    
        axios.post("/check-affiliate-code", formData).then((response) => {
            hideMessage()
            if (response.data.success) {
                this.setState({ loader: false })
                if(response.data.isValid == 'YES'){
                    this.setState({ acode: acode,registration_source:source,acode_msg:response.data.message,acode_class : "success" });
                }else{
                    this.setState({ acode: "",registration_source:"",acode_msg:response.data.message,acode_class : "danger" });
                }
            }
        })
        .catch((error) => {
            hideMessage()
            message.error(error.response.data.message)
            this.setState({ loader: false })
            this.setState({ acode: "",registration_source:"" });
        });   
    }

    componentDidMount() {
        if (this.token && this.userdata) {
            if (JSON.parse(this.userdata).account_type !== "jobseeker") {
                window.location = '/profile-coach'
            } else if (JSON.parse(this.userdata).account_type === "jobseeker") {
                window.location = "/webinars"
            }
        }
        var queryString = window.location.search
        if (queryString) {
            let URLparams = new URLSearchParams(queryString);
            if(URLparams.get('package') ){
                this.setState({package : URLparams.get('package')})
            }

            if(URLparams.get('firstname') ){
                let {FromDataError} = this.state
                FromDataError.firstname = "";
                this.setState({firstname : URLparams.get('firstname'),FromDataError})
            }
            if(URLparams.get('lastname') ){
                let {FromDataError} = this.state
                FromDataError.lastname = "";
                this.setState({lastname : URLparams.get('lastname'),FromDataError})
            }
            if(URLparams.get('email') ){
                let {FromDataError} = this.state
                FromDataError.email = "";
                this.setState({email : URLparams.get('email'),FromDataError})
            }

            if(URLparams.get('source') ){
                let source = URLparams.get('source');
                this.setState({ registration_source:source});
                if(source == "NEXGEN"){
                    let acode = URLparams.get('acode');
                    this.acode = acode;
                    if(acode && acode !="undefined" || acode != ""){
                        this.checkAffiliateCode(acode,source);
                    }
                }
                let coupon =  URLparams.get('promo');
                if(coupon && coupon !="undefined" || coupon != ""){
                    this.checkCoupon(coupon);
                }
            }
            localStorage.setItem('tracking_link', window.location.href)
        }
        else {
            console.log('notfound')
        }
        this.getGeoInfo()
    }

    componentWillMount() {
        this.getTermsContent()
    }

    validateForm(errors) {
        let valid = true;
        let sta = this;
        let FromDataError = this.state.FromDataError;

        for (const [key, value] of Object.entries(errors)) {
            if (value.length > 0) {

                if (key !== 'user_type' && value === 'false') {
                    FromDataError[key] = 'This field is required';
                    sta.setState({ FromDataError })
                }
                else if (key === 'user_type' && value === 'false') {
                    FromDataError[key] = 'Please select any user type to sign up';
                    sta.setState({ FromDataError })
                }

                valid = false;
            }
        }

        return valid;
    };

    termsModal = (show) => {
        this.setState({ termsModal: show })
    }

    getTermsContent = () => {
        let self = this
        axios.get('/cmsfooter/footerContent').then(async (response) => {
            if (response.status === 200) {
                self.setState({
                    termsdata: response.data.value.terms_conditions[0],
                    content: response.data.value.terms_conditions[0].content_json,
                    cont: response.data.terms_conditionscont
                })
            }
        }).catch((e) => {
            console.log(e)
        })
    }

    AccetTerms = (term) => {
        this.setState({ acceptTerms: term })

        this.socialStateChange()
        this.termsModal(false)
    }

    handleChange = (e) => {
        let { FromDataError } = this.state
        e.persist();
        var valid_obj = {
            value: e.target.value,
            rules: e.target.getAttribute("validaterule"),
            message: e.target.getAttribute("validatemsg")
        }

        validation(valid_obj).then((error) => {

            FromDataError[e.target.name] = error
            this.setState({ FromDataError })

            // if (e.target.name === "password" || e.target.name === "RepeatPassword") {
            //     if (e.target.name === "password" && e.target.value !== this.state.RepeatPassword && this.state.RepeatPassword !== "") {
            //         FromDataError['RepeatPassword'] = "Password and confirm password do not match"
            //         this.setState({ FromDataError })
            //     }
            //     else if (e.target.name === "password" && e.target.value === this.state.RepeatPassword && this.state.RepeatPassword !== "") {
            //         FromDataError['RepeatPassword'] = ""
            //         this.setState({ FromDataError })
            //     }
            //     else if (e.target.name === "RepeatPassword" && e.target.value !== this.state.password && this.state.password !== "") {
            //         FromDataError['RepeatPassword'] = "Password and confirm password do not match"
            //         this.setState({ FromDataError })
            //     }
            // }

            setTimeout(() => {
                this.setState({
                    [e.target.name]: e.target.value,
                });
            }, 100);
        })
    };

    handleChangephone = (e, data) => {
        let FromDataError = this.state.FromDataError
        if ((e.length - data.dialCode.length) >= 10) {
            console.log('true')
            FromDataError.phone_number = ''
        } else {
            console.log('false')
            FromDataError.phone_number = 'Enter valid phone number'
        }
        this.setState({ FromDataError });
        setTimeout(() => {
            this.setState({ ['phone_number']: e, });
        }, 100);
    };

    handleSubmit = (e) => {
        if(e){
            e.preventDefault();
        }
        if (this.validateForm(this.state.FromDataError)) {
            var tracking_link = '';
            if (localStorage.getItem('tracking_link') !== undefined || localStorage.getItem('tracking_link') != null) {
                tracking_link = localStorage.getItem('tracking_link')
            }

            if(!this.state.otp_validated){
                this.sendOTP({
                    fullname: `${this.state.fastname.trim() + " " + this.state.lastname.trim()}`,
                    email: this.state.email,
                    phone_number: this.state.phone_number,
                    tracking_link : tracking_link,
                    account_type : "jobseeker"
                });
                return;
            }

            this.setState({ loader: true });
            const hideMessage = message.loading('Please wait, we are saving your data ...', 0)


            axios.post("/check-email",{email : this.state.email}).then(async (response) => {
                if (response.status === 200) {
                  if(response.data.success){
                    if(response.data.proceed){
                        const formData = {
                            fullname: this.state.email.split('@')[0],
                            //fullname: `${this.state.fastname.trim() + " " + this.state.lastname.trim()}`,
                            email: this.state.email,
                            phone_number: this.state.phone_number,
                            // password: this.state.password,
                            password: generatePassword(),
                            RepeatPassword: this.state.RepeatPassword,
                            account_type: this.state.user_type,
                            getNoti: this.state.getNoti,
                            tracking_link: tracking_link,
                            registration_source : this.state.registration_source,
                            acode :this.state.acode,
                            package : this.state.package,
                            plan_type : ALLLOW_FREE_SUBSCRIPTION ? "FREE" : "PAID",
                            coupon : this.state.coupon,
                        };
            
                        axios.post("/signup", formData).then((response) => {
                            hideMessage()
                            console.log("responsee", response.data);
                            if (response.data.success) {
                                this.setState({ loader: false })
                                let Uuid = response.data.userdata.uid;
                                message.success(response.data.message);
                                localStorage.removeItem('tracking_link')
                                this.props.history.push({ pathname: "/thankyou", state: { user_t: Uuid, userData: response.data.userdata, }, });
                            }
                        })
                            .catch((error) => {
                                hideMessage()
                                message.error(error.response.data.message)
                                this.setState({ loader: false })
                            });        
                    }else{
                     hideMessage()
                     message.error("Please enter a valid email")
                     this.setState({ loader: false })
                    }
                    
                  }else{
                     hideMessage()
                     message.error(response.data.message)
                     this.setState({ loader: false })
                  }
                } else {
                     hideMessage()
                     message.error("There is some issue while processing your request")
                     this.setState({ loader: false })
                }
              })
              .catch((e) => {
                console.log(e);
                hideMessage()
                 message.error("There is some issue while processing your request")
                 this.setState({ loader: false })
              });
        }
    }

    SetNotification(e) {
        if (this.state.getNoti) {
            this.setState({ getNoti: false }, () => { console.log(this.state) })
        }
        else {
            this.setState({ getNoti: true }, () => { console.log(this.state) })

        }
        // console.log(e.target)
    }

    socialStateChange = (e) => {
        if (this.state.socialState === true) {
            this.setState({ socialState: false })
        } else {
            this.setState({ socialState: true })
        }
    }

    termsAndcondition = async () => {
        let { socialState, user_type, FromDataError } = this.state
        if (socialState === true) {
            message.error('Please select terms & conditions first');
        } else {
            if (user_type === -1) {
                FromDataError['user_type'] = 'Please select any user type to sign up';
                this.setState({ FromDataError })
                message.error('Please select any user type to sign up');
            }
            else {
                console.log('terms')
                await this.setState({ socialState: false })
            }
        }
    }

    getGeoInfo = () => {
        axios.get('https://ipapi.co/json/').then((response) => {
            let data = response.data;
            //console.log('dataaaaaaaaaaa cuntry', data)
            this.setState({
                countryName: data.country.toLowerCase(),
            });
        }).catch((error) => {
            console.log(error);
        });
    };

    capchaChange = (value) => {
        console.log("Captcha value:", value);
        let {  FromDataError } = this.state;
        FromDataError.recapcha = "";
        this.setState({ recapcha : value, FromDataError }, () => {
          this.validateForm(FromDataError);
        });
        let th = this;
        setTimeout(function() {
                FromDataError.recapcha = "false";
                th.setState({ recapcha : "", FromDataError }, () => {
                th.validateForm(FromDataError);
            });
        }, 120000); 
      };

      handleResendOTP(){
        var tracking_link = '';
        if (localStorage.getItem('tracking_link') !== undefined || localStorage.getItem('tracking_link') != null) {
            tracking_link = localStorage.getItem('tracking_link')
        }
        this.sendOTP({
            fullname: `${this.state.fastname.trim() + " " + this.state.lastname.trim()}`,
            email: this.state.email,
            phone_number: this.state.phone_number,
            tracking_link : tracking_link,
            is_resend : true,
            account_type : "jobseeker"
        })
      }

      handleSubmitOTP(otp){
        let data = {otp : otp,email : this.state.email};
        this.setState({ loader: true });
        const hideMessage = message.loading('Please wait, we are Validating the OTP', 0)

        axios.post("/validate-otp", data).then((response) => {
            hideMessage();
            this.setState({ loader: false });
            if (response.data.success) {
                this.setState({otp_validated : true,otp_msg : response.data.message,otp_msg_class : "success"});
                let thhss = this;
                setTimeout(function(){
                    thhss.setState({otp_msg : "",show_otp_modal : false});
                    thhss.handleSubmit();
                },1500)                
            }else{
                this.setState({otp_msg : response.data.message,otp_msg_class : "danger"});
            }
        }).catch((error) => {
            hideMessage();
            this.setState({ loader: false });
            console.log("Error in OTP send", error)

        });
    }
    
    sendOTP(data){
        this.setState({ loader: true });
        const hideMessage = message.loading('Please wait, we are sending the OTP', 0)

        axios.post("/send-otp", data).then((response) => {
            hideMessage();
            this.setState({ loader: false });
            if (response.data.success) {
                this.setState({otp_msg : response.data.message,show_otp_modal : true});
            }else{
                message.error(response.data.message)
                this.setState({otp_msg : "",show_otp_modal : false});
            }
        }).catch((error) => {
            hideMessage();
            this.setState({ loader: false });
            console.log("Error in OTP send", error)

        });
    }
    render() {
        // return <div>
        //         <HeaderTop />
        //         <Header />
        //         <h3 style={{"margin-top" : "5rem","text-align":"center"}}>Coming Soon</h3>
        // </div>

        return (
            <div>
                <HeaderTop />
                <Header />

               {this.state.show_otp_modal && 
               <OtpModal 
                show_otp_modal={this.state.show_otp_modal} 
                otp_msg_class = {this.state.otp_msg_class}
                otp_msg = {this.state.otp_msg}
                handleShowOtpModal={()=>{this.setState({show_otp_modal:false})}}
                handleResendOTP={this.handleResendOTP}
                handleSubmitOTP={this.handleSubmitOTP}
                /> 
                }
                <section className="registrationWrap registrationWrapNew">
                    <div className="container">
                        <div className="registrationContent">
                            <div className="row ">
                                <div className="col-md-4 col-12 otherRegistration">
                                {/* <SocilaLogin isLogin={false} txt="Sign Up with Google"
                                registration_source = {this.state.registration_source}
                                acode = {this.state.acode}
                                package = {this.state.package}
                                coupon = {this.state.coupon}
                                /> */}
                                </div>
                                <div className="col-md-8 col-12">
                                    <div className="registrationForm">
                                        <h3><b>SIGN UP</b> <span className="float-right hoverClass" style={{"font-size":"14px","color":"#2f2f2f"}}>Are You a coach ? <u><Link to="/registration">Sign up Here</Link></u> </span></h3> <br />
                                        <Row>
                                           
                                        <Col md={10}>
                                        <div className="form-group mb-4">
                                            <div className="areas">
                                            {/* {!this.acode && <>
                                        <p htmlFor=""><b style={{"fontSize":"16px","color":"#000"}}> 7 day FREE trial  (no credit card required) </b> <br />
                                        Introductory offer for new members. Our free trial gives you access to Coach Master for 7 days, for absolutely nothing! </p> 
                                        </>} */}

                                            {!this.acode && <>
                                        <p htmlFor=""><b style={{"fontSize":"16px","color":"#000"}}> Get Started For Free (no credit card required)</b> <br />
                                        Join live coaching webinar events every month, plus watch free on-demands</p> 
                                        </>}
                                                    
                                            </div>
                                            {this.state.acode_msg && <label className={`text-${this.state.acode_class}`}>{this.state.acode_msg}</label>}
                                        </div>
                                        </Col>
                                        </Row>

                                        <Form onSubmit={this.handleSubmit} id="handleSubmit">
                                            <Row>
                                                {/* <Col md={4}>
                                                    <Form.Group>
                                                        <Form.Label>First Name <span className="text-danger">*</span></Form.Label>
                                                        <Form.Control type="text" onChange={this.handleChange} name="fastname" required={true} validaterule={['required']} validatemsg={['Please enter your first name']} pattern="[a-zA-Z]*" defaultValue={this.state.firstname}/>
                                                        <p style={{ color: 'red' }}>{this.state.FromDataError.fastname === 'false' ? '' : this.state.FromDataError.fastname}</p>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={4}>
                                                    <Form.Group>
                                                        <Form.Label>Last Name <span className="text-danger">*</span></Form.Label>
                                                        <Form.Control type="text" onChange={this.handleChange} name="lastname" required={true} validaterule={['required']} validatemsg={['Please enter your last name']} pattern="[a-zA-Z]*" defaultValue={this.state.lastname} />
                                                        <p style={{ color: 'red' }}>{this.state.FromDataError.lastname === 'false' ? '' : this.state.FromDataError.lastname}</p>
                                                    </Form.Group>
                                                </Col> */}
                                                <Col md={10}>
                                                    <Form.Group>
                                                        <Form.Label style={{"color":"#2f2f2f"}}>Email address <span className="text-danger">*</span> (Sign up with Google or continue with email)</Form.Label>
                                                        <Form.Control type="email" className="custom_input_box" onChange={this.handleChange} required={true} name="email" validaterule={['required', 'isEmail']} validatemsg={['Your email address is required', 'Enter a valid email address']} defaultValue={this.state.email} placeholder="Enter your email address"/>
                                                        <span style={{ color: 'red' }}>{this.state.FromDataError.email === 'false' ? '' : this.state.FromDataError.email}</span>
                                                    </Form.Group>
                                                </Col>
                                                {/* <Col md={12}>
                                                    <Form.Group>
                                                        <Form.Label>Phone Number <span className="text-danger">*</span></Form.Label>
                                                        <PhoneInput 
                                                        name="phone_number" 
                                                        specialLabel={''} 
                                                        required={true} 
                                                        country={"us"}
                                                        onlyCountries = {showPhonecountry} 
                                                        countryCodeEditable={false} 
                                                        inputClassName="form-control phone-reg" 
                                                        placeholder="Phone Number" 
                                                        onChange={this.handleChangephone} 
                                                        validaterule={['required', 'phone']} 
                                                        validatemsg={['Phone number is required', 'Please enter a valid phone number']} />
                                                        <p style={{ color: 'red' }}>{this.state.FromDataError.phone_number === 'false' ? '' : this.state.FromDataError.phone_number}</p>
                                                    </Form.Group>
                                                </Col> */}
                                                {/* <Col md={6}>
                                                    <Form.Group>
                                                        <Form.Label>Create password <span className="text-danger">*</span></Form.Label>
                                                        <Form.Control type="password" 
                                                        onChange={this.handleChange} 
                                                        required={true} name="password" 
                                                        defaultValue = {this.state.password}
                                                        validaterule={['required', 'password','passpattern']} 
                                                        validatemsg={['Password field is required', 'Password length must be at least 6 characters','Password must contain at least one special character and one numeric digit']} />
                                                        <p style={{ color: 'red' }}>{this.state.FromDataError.password === 'false' ? '' : this.state.FromDataError.password}</p>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group>
                                                        <Form.Label>Repeat password <span className="text-danger">*</span></Form.Label>
                                                        <Form.Control type="password" onChange={this.handleChange} required={true} name="RepeatPassword" validaterule={['required', 'same|' + this.state.password]} validatemsg={['Confirm your password field', 'Password and confirm password do not match']} />
                                                        <p style={{ color: 'red' }}>{this.state.FromDataError.RepeatPassword === 'false' ? '' : this.state.FromDataError.RepeatPassword}</p>
                                                         
                                                         <br />
                                                         <span className="defaultBtn btn btn-default" onClick={this.generatePassword}>Generate Password</span>
                                                         {this.state.msg_password != "" && <p className="text-success">{this.state.msg_password}</p>}
                                                    </Form.Group>
                                                </Col> */}
                                                <Col md="12">
                                                <Form.Group className="">
                                                <label htmlFor="" style={{"color":"#2f2f2f","fontSize":"14px"}}>By sharing your email, you agree to our <Link to='/#' onClick={(e) => { e.preventDefault(); this.termsModal(true) }} data-toggle="modal" data-target="#bannerformmodal">
                                                                Terms & Conditions
                                                            </Link>.</label>
                                                    {/* <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input" id="terms" name="terms" required={true} defaultChecked={this.state.acceptTerms} onClick={() => this.AccetTerms(!this.state.acceptTerms)} />
                                                        <label className="custom-control-label"htmlFor="terms">
                                                            I accept the{" "}
                                                            <Link to='/#' onClick={(e) => { e.preventDefault(); this.termsModal(true) }} data-toggle="modal" data-target="#bannerformmodal">
                                                                Terms & Conditions
                                                            </Link>.
                                                        </label>
                                                        <label htmlFor="" style={{"color":"#2f2f2f"}}>By sharing your email, you agree to our <Link to='/#' onClick={(e) => { e.preventDefault(); this.termsModal(true) }} data-toggle="modal" data-target="#bannerformmodal">
                                                                Terms & Conditions
                                                            </Link>.</label>
                                                    </div> */}
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input" id="notification" name="notification" value={this.state.getNoti} onChange={() => { this.SetNotification(this) }} />
                                                        <label className="custom-control-label"htmlFor="notification" style={{"color":"#2f2f2f","fontSize":"14px"}}>Receive free Coach Master newsletters</label>
                                                    </div>
                                                </Form.Group>
                                                </Col>
                                                <Form.Row className="col-12">
                      <Form.Group
                        controlId="formBasicEmail"
                        as={Col}
                        className="mt-2 custom_captcha"
                      >
                        <ReCAPTCHA
                              sitekey={CaptchaKey}
                              onChange={this.capchaChange}
                            />

                        <p style={{ color: "red" }}>
                          {this.state.FromDataError.recapcha == "false"
                            ? ""
                            : this.state.FromDataError.recapcha}
                        </p>
                      </Form.Group>
                    </Form.Row>
                    <Col md={12}>
                        <Form.Group className="">
                            <Row>
                                <Col md="12" className="d-flex"><Button variant="default" disabled={this.state.loader} className="defaultBtn" type="Submit"
                            style={{"fontSize":"13px","border": "1px solid #000","borderRadius": "10px","textTransform": "capitalize"}}>Create Account</Button>
                                <div md="5" className="custom_google_btn">
                                <SocilaLogin isLogin={false} txt="Sign Up with Google"
                                registration_source = {this.state.registration_source}
                                acode = {this.state.acode}
                                package = {this.state.package}
                                coupon = {this.state.coupon}
                                />
                                </div>
                                </Col>
                                <Col md="12">
                                    <p className="mt-3 loginLink" style={{"color":"#2f2f2f","fontSize":"14px"}}>Have an account?&nbsp;&nbsp;<Link style={{"color":"#2f2f2f"}} to="/login"><u>LOGIN</u></Link> | <Link to="/forgotpassword" style={{"color":"#2f2f2f"}}>Forgot Password</Link></p>
                                </Col>
                            </Row>
                            

                            

                        </Form.Group>
                    </Col>
                </Row>
                                           
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="modal fade bannerformmodal" id="bannerformmodal" tabIndex="-1" role="dialog" aria-labelledby="bannerformmodal" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalCenterTitle" style={{ color: "black" }}>
                                    Terms and Conditions
                                </h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <ol className="list-unstyled">
                                    <li>
                                        <small style={{ color: "black" }}>
                                            <span className="text-primary text-small">1. </span>
                                            Accept terms and Condition
                                        </small>
                                    </li>
                                    <li>
                                        <small style={{ color: "black" }}>
                                            <span className="text-primary text-small">2. </span>
                                            Accept terms and Condition
                                        </small>
                                    </li>
                                </ol>
                            </div>
                            <div className="modal-footer">
                                <button type="button" disabled={this.state.loader} className="btn btn-secondary" data-dismiss="modal">
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal aria-labelledby="example-custom-modal-styling-title" show={this.state.termsModal} onHide={() => this.termsModal(false)} dialogClassName="modal-60w" backdrop="static">
                    <div className="modal-content" style={{ background: "#f5f5f5", padding: "10px" }}>
                        <div className="modal-header" style={{ paddingLeft: "0px" }}>
                            <h5 className="modal-title" style={{ color: "black" }}>
                                Terms & Conditions
                            </h5>
                        </div>
                        <div className="modal-body">  <div className="text-left" style={{ color: 'black' }} dangerouslySetInnerHTML={{ __html: this.state.cont }} /></div>
                        <div className="modal-footer">
                            <Button className="btn btn-success" onClick={() => this.AccetTerms(true)}>Accept</Button>
                            <Button className="btn btn-danger" onClick={() => this.termsModal(false)}>Decline</Button>
                        </div>
                    </div>
                </Modal>
                <FooterLink />
            </div>
        );
    }
}

const OtpModal = (props)=>{
    const [otp,setOTP] =  useState("");

    const [otp_error,setOtpError] =  useState(""); 
    const handleSubmitOTP = ()=>{
        
        if(otp == "" || otp.length == 0){
            setOtpError("Please enter the OTP");
            return;
        }

        if(otp.length > 6 ||  otp.length < 6){
            setOtpError("OTP should be 6 digit length");
            return;
        }
        setOtpError("");
        props.handleSubmitOTP(otp);
    }
    return <>
    <Modal show={props.show_otp_modal} onHide={props.handleShowOtpModal} className="">
    <Modal.Body>
    <div className="alert__icon">
    </div>
    {props.otp_msg && <p className={props.otp_msg_class == "danger" ? 'text-danger' : "text-success"}>{props.otp_msg}</p> }
    
    {/* <input
      type="text"
      className="form-control"
      style={{marginBottom: "10px"}}
      maxLength="6"
      minLength="6"
      onChange={(e)=>{
        setOTP(e.target.value);
      }}
    /> */}
    <div className="mb-3 p-5">
        <p style={{color:"black"}} >Enter passcode:</p>
        <OTPInput  value={otp} onChange={setOTP} autoFocus OTPLength={6} otpType="number"/>
    </div>
    <p className="text-danger">{otp_error}</p>
    <ul style={{display: "flex", justifyContent: "space-between","listStyle": "none"}}>
      <li style={{flex: "none"}} className="alert__cancel">
        <button
          className="btn btn-secondary"
          onClick={props.handleShowOtpModal}
        >
          Exit
        </button>
      </li>
      <li style={{flex: "none"}} className="alert__resend">
        <button
          className="btn btn-primary"
          onClick={props.handleResendOTP}
        >
          Resend OTP
        </button>
      </li>
      <li style={{flex: "none"}} className="alert__confirm">
        <button
          className="btn btn-success"
          onClick={handleSubmitOTP}
        >
          Submit
        </button>
      </li>
    </ul>
  </Modal.Body>
</Modal>
</>
}
export default JobSeekerRegistration;
